<template>
  <div>
    <b-button
      block
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'dark' : ''"
      class="d-md-none mb-1"
      @click="isHidden = !isHidden"
    >
      <div>
        <feather-icon
          icon="MenuIcon"
          :class="$store.state.appConfig.layout.isRTL ? 'left-position' : 'right-position'"
          size="20"
        />
      </div>
    </b-button>
    <b-card
      class="mb-2 rounded remove-padding"
      :class="(isHidden ? 'd-md-block  d-none' : '')"
    >
      <b-tabs
        v-model="tabNumber"
        :class="$store.state.appConfig.layout.skin === 'dark' ? 'custom-tab-bg-blue' :'custom-tab-bg-aliceblue'"
        content-class="col-12 col-md-12 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-12 col-12"
        class="row pannelIB-tab"
        @input="findTab"
      >
        <b-tab
          v-for="tab in filtredtabsItem"
          :key="tab.number+'ibtabs'"
          active
          title-item-class="w-100 w-md-200"
          @click="tabClick(tab)"
        >
          <!-- title -->
          <template
            v-if="!tab.hidden"
            #title
          >
            <div :class="$store.state.appConfig.layout.skin === 'dark' ? 'text-white' :''">
              <component
                :is="tab.icon"
                class="mr-50"
              />
              <span class="font-weight-bold default">
                {{ $t(tab.title) }}
              </span>
            </div>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import {
  BTabs, BTab, BIcon, BCard, BIconBorderAll,
  BIconPerson,
  BIconBank,
  BIconFileEarmarkSpreadsheet,
} from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BTab,
    BIcon,
    BCard,
    BIconBorderAll,
  },
  data() {
    return {
      tabNumber: 0,
      isHidden: false,
      finalTabItems: [],
    }
  },
  computed: {
    route() {
      return this.$route.path
    },
    filtredtabsItem() {
      return [
        {
          title: 'general',
          link: { name: 'document-general', params: {} },
          number: 0,
          icon: BIconPerson,
        },
        {
          title: 'bank',
          link: { name: 'document-bank', params: {} },
          number: 1,
          icon: BIconBank,
        },
        {
          title: 'legal',
          link: { name: 'document-legal', params: {} },
          number: 2,
          icon: BIconFileEarmarkSpreadsheet,
          hidden: !this.$store.state.auth.user.is_legal,
        },
      ].filter(tab => !tab.hidden || tab.hidden !== true)
    },

  },
  watch: {
    route() {
      this.findTab()
    },
  },
  methods: {
    areObjectsEqual(obj1, obj2) {
      // Check if both arguments are objects
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return false
      }

      // Get the keys of both objects
      const keys1 = Object.keys(obj1)
      const keys2 = Object.keys(obj2)

      // Check if the number of keys is different
      if (keys1.length !== keys2.length) {
        return false
      }

      // Check if all keys and values in obj1 exist in obj2
      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys1) {
        if (obj2[key] !== obj1[key]) {
          return false
        }
      }

      return true
    },
    findTab() {
      this.$nextTick(() => {
        const routeParams = JSON.parse(JSON.stringify(this.$route.params))
        delete routeParams.lang
        const tabNumber = this.filtredtabsItem.find(tab => (tab.link.name === this.$route.name
         && this.areObjectsEqual(routeParams, tab.link.params))).number
        if (tabNumber || tabNumber === 0) {
          this.tabNumber = tabNumber
        } else {
          throw new Error('cant find tab number')
        }
      })
    },
    tabClick(tab) {
      this.$router.push(tab.link)
    },
  },
}
</script>
  <style lang="scss" scoped>
  ::v-deep.router-link-exact-active {
    color:white
  }
.custom-tab-bg-aliceblue ::v-deep.nav-pills .nav-link.active{
  background-color: $primary !important;
}
.custom-tab-bg-aliceblue ::v-deep.nav-pills .nav-link{
  background-color: aliceblue  ;

}
.custom-tab-bg-blue ::v-deep.nav-pills .nav-link{
  background-color: rgb(155, 190, 221);

}
[dir] .tabs ::v-deep.nav-pills .nav-link{
  margin: 3px !important;
}
.remove-padding .card-body{
  padding: 0;
}
  </style>
