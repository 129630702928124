<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/valid-v-bind -->
<template>
  <div>
    <documentTabs />
    <router-view />
  </div>
</template>
<script>
import documentTabs from './documentTabs.vue'

export default {
  components: {
    documentTabs,
  },
}
</script>
